import {createRoot} from "react-dom/client";
import RegionShopSearchContainer from "../../../../src/web/regions/sp/RegionShopSearchContainer";

document.addEventListener("DOMContentLoaded", function(event) {
  const container = document.getElementById('region_shop_search_container');
  if (container) {
    const root = createRoot(container);
    root.render(<RegionShopSearchContainer />, );
  }
});
