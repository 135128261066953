// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-assignment
// @see
// app/views/bargain/shop_search_by_prefectures/show.html.haml
// app/javascript/packs/components_regions_prefecture_index_selector_pc.vue
import React from "react";
import InputZipCode from "@/src/web/regions/sp/RegionIndexSelectors/InputZipCode";
import RegionIndexGroups from "@/src/web/regions/sp/RegionIndexSelectors/RegionIndexGroups";
import {WindowComponentForSelectors as WindowComponent, WindowComponentTypeNames} from "@/src/web/regions/types";

type RegionShopSearchWindowComponentView = {
  isActive: boolean;
  error: string | null;
  windowComponent: WindowComponent;
  hideWindowComponent: () => void;
  switchWindowComponent: (type: WindowComponentTypeNames) => void;
  showNestedUrlComponent: (type: WindowComponentTypeNames, url: string) => void;
}

export default function RegionShopSearchWindowComponentView({
  isActive,
  error,
  windowComponent,
  hideWindowComponent,
  switchWindowComponent,
  showNestedUrlComponent
}: RegionShopSearchWindowComponentView) {
  const isRegionComponent = () => {
    return windowComponent.type === 'region';
  }

  const showBackLinkInHeader = () => {
    return windowComponent.prefectureName && windowComponent.backLinkUrl;
  }

  const isZipCodeComponent = () => {
    return windowComponent.type === 'zipcode';
  }

  return (
    <>
      <div
        className={`region_index selector_window_wrapper ${isActive ? '' : 'hidden'}`}
      >
        <div className="region_index_selector_window selector_window">
          <div className="region_index_header">
            <div className="name">
              {windowComponent.title}
            </div>
            <div className="close_link" onClick={hideWindowComponent}>
              <div className="icon_font icf_close"/>
            </div>
            {showBackLinkInHeader() && (
              <div className="back_link">
                <a
                  href={windowComponent.backLinkUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    switchWindowComponent(windowComponent.type as WindowComponentTypeNames);
                  }}
                >
                  <span className="icon_font icf_arrow_left" />
                  <span className="text">戻る</span>
                </a>
              </div>
            )}
          </div>
          {!isRegionComponent() && windowComponent.prefecturePath && (
            <div className='target_region_link'>
              <a href={windowComponent.prefecturePath}>
                { windowComponent.prefectureName }の店舗をみる
                <span className='icon_font icf_arrow_right'/>
              </a>
            </div>
          )}
          <div className="window_inner">
            {isZipCodeComponent() ? (
              <InputZipCode />
            ) : (
              <RegionIndexGroups
                windowComponent={windowComponent}
                showNestedUrlComponent={showNestedUrlComponent}
                error={error}
              />
            )}
          </div>
          <div className="tail"></div>
        </div>
      </div>
    </>
  );
}
