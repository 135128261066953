// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-assignment
import React from "react";
import {recommend_with_zip_code_bargains_path} from "@/src/web/routes";

export default function InputZipCode() {
  return (
    <div className='zip_code_search_wrapper'>
      <div className='zip_code_search_title'>郵便番号を入力してください</div>
      <div className='form_container'>
        <form
          data-alter-path={recommend_with_zip_code_bargains_path()}
          action={recommend_with_zip_code_bargains_path()}
          acceptCharset='UTF-8'
          method="get"
          className="zip_code_search_form search_form"
        >
          <input
            type="text"
            name="zip_code"
            id="zip_code"
            placeholder="1080073"
            className="zip_code"
          />
          <input
            type="submit"
            name="commit"
            value="検索"
            data-track-ct-group="bargain_about"
            data-track-ct-place="zip_code_search_pc"
            className="submit_button button"
          />
        </form>
      </div>
    </div>
  );
}
