// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-assignment
import {useEffect, useState} from "react";
import useFetchWindowComponent, {FetchWindowComponentProps} from "@/src/web/regions/hooks/useFetchWindowComponent";
import RegionShopSearchWindowComponentView
  from "@/src/web/regions/sp/RegionIndexSelectors/RegionShopSearchWindowComponentView";
import {
  WindowComponentTypeNames as ComponentNames,
} from "@/src/web/regions/types";

const RegionLinkSelectors = {
  'area': '.shop_search_link.main.area',
  'zipcode': '.shop_search_link.sub.zipcode',
  'rail_line': '.shop_search_link.sub.station',
  'city': '.shop_search_link.sub.city',
}

export default function RegionShopSearchContainer() {
  const [fetchWindowComponentProps, setFetchWindowComponentProps] = useState<FetchWindowComponentProps>({ type: null, url: null });
  const { component, error } = useFetchWindowComponent(fetchWindowComponentProps);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [currentRequestId, setCurrentRequestId] = useState<string>('');

  useEffect(() => {
    const areaSelector = document.querySelector('ul.shop_search_main_list > li > a.shop_search_link.main.area');
    areaSelector.addEventListener('click', buildComponentByType);
    const subSelectors = document.querySelectorAll('ul.shop_search_sub_list > li > a.shop_search_link.sub');
    subSelectors.forEach((subSelector) => {
      subSelector.addEventListener('click', buildComponentByType);
    });
    return () => {
      areaSelector.removeEventListener('click', buildComponentByType);
      subSelectors.forEach((subSelector) => {
        subSelector.removeEventListener('click', buildComponentByType);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildComponentByType = (event: Event) => {
    event.preventDefault();
    const dataset = (event.target as HTMLElement).dataset;
    if (!('type' in dataset)) return;

    const type = dataset.type;
    const componentProps = buildWindowComponentPropsByType(type as ComponentNames);

    setFetchWindowComponentProps(componentProps);
    showWindowComponent();
  }

  const buildWindowComponentPropsByType = (type: ComponentNames) => {
    let url = '';
    const selector = RegionLinkSelectors[type as 'zipcode' | 'city' | 'rail_line'];
    if (selector) {
      const forTargetElem = document.querySelector(selector);
      if (forTargetElem) {
        url = (forTargetElem as HTMLAnchorElement).href;
      }
    }
    return { type, url, requestId: generateRequestId() };
  }
  
  const generateRequestId = () => {
    const newRequestId = `${Math.random()}`;
    setCurrentRequestId(newRequestId);
    return newRequestId;
  }

  const switchWindowComponent = (type: ComponentNames) => {
    if (type === 'allRegion') {
      setFetchWindowComponentProps({ type: type as ComponentNames, url: component.homePath, requestId: generateRequestId() });
    } else {
      const componentProps = buildWindowComponentPropsByType(type as ComponentNames);
      setFetchWindowComponentProps(componentProps);
    }
    showWindowComponent();
  }

  const showWindowComponent = () => {
    setIsActive(true);
  }

  const hideWindowComponent = () => {
    setIsActive(false);
  }

  const RailLinesShowUrlPrefix = '/transit/rail_lines/';
  const showNestedUrlComponent = (type: ComponentNames, url: string) => {
    let nextType = type;
    if (url && url.startsWith(RailLinesShowUrlPrefix)) {
      // モーダル内の画面切り替えで type が変わるのは rail_line(路線) → station_group(駅) のみ
      nextType = 'station_group' as ComponentNames;
    }
    setFetchWindowComponentProps({ type: nextType, url, requestId: generateRequestId() });
  }

  if (!component) { return null; }
  // API の fetching 完了前に別の要素に切り替わる可能性があるため、現在選択中の リクエスト ID と hooks に渡したリクエスト ID が一致するか確認する
  if (!component.fetching && (!component.requestId || component.requestId !== currentRequestId)) {
    return <RegionShopSearchWindowComponentView
      isActive={isActive}
      error={error}
      windowComponent={{ ...component, fetching: true }}
      hideWindowComponent={hideWindowComponent}
      switchWindowComponent={switchWindowComponent}
      showNestedUrlComponent={showNestedUrlComponent}
    />;
  }

  return <RegionShopSearchWindowComponentView
    isActive={isActive}
    error={error}
    windowComponent={component}
    hideWindowComponent={hideWindowComponent}
    switchWindowComponent={switchWindowComponent}
    showNestedUrlComponent={showNestedUrlComponent}
  />;
}
