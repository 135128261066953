// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-assignment
import React, {useEffect} from "react";
import {
  Content,
  WindowComponentForSelectors as WindowComponent,
  WindowComponentTypeNames
} from "@/src/web/regions/types";

type RegionIndexGroupsProps = {
  windowComponent: WindowComponent;
  showNestedUrlComponent: (type: WindowComponentTypeNames, url: string) => void;
  error: string | null;
}

export default function RegionIndexGroups({
  windowComponent,
  showNestedUrlComponent,
  error
}: RegionIndexGroupsProps) {
  return (
    <div className="region_index_groups">
      {error && <div>情報の取得に失敗しました</div>}
      {windowComponent.fetching && (
        <div className='spinner'>
          <div className={'indicator'}/>
        </div>
      )}
      {windowComponent.contentGroups.length > 1 ? (
        <ContentGroups
          windowComponent={windowComponent}
          showNestedUrlComponent={showNestedUrlComponent}
        />
      ) : (
        <ContentGroup
          windowComponent={windowComponent}
          showNestedUrlComponent={showNestedUrlComponent}
        />
      )}
    </div>
  );
}

const ContentGroup = ({windowComponent, showNestedUrlComponent}: {
  windowComponent: WindowComponent;
  showNestedUrlComponent: (type: WindowComponentTypeNames, url: string) => void;
}) => {
  return (
    windowComponent.contentGroups.map((group, groupIndex) => (
      <React.Fragment key={groupIndex}>
        {group.name && <div className="group_name">{group.name}</div>}
        <ul className={group.popular ? 'popular_group_for_indexes' : 'group_for_indexes'}>
          {group.contents.map((content, contentIndex) => (
            <li className="leaf_wrapper" key={contentIndex}>
              {!content.nested ? (
                <ContentLink content={content} groupPopular={group.popular}/>
              ) : (
                <ContentNestedLink
                  content={content}
                  windowComponentType={windowComponent.type as WindowComponentTypeNames}
                  showNestedUrlComponent={showNestedUrlComponent}
                />
              )}
            </li>
          ))}
        </ul>
      </React.Fragment>
    ))
  )
}

const ContentGroups = ({windowComponent, showNestedUrlComponent}: {
  windowComponent: WindowComponent,
  showNestedUrlComponent: (type: WindowComponentTypeNames, url: string) => void;
}) => {
  useEffect(() => {
    // コンポーネントがリフレッシュされないと、トグルステータスが前回のままになってしまうので、マウント時に初期化する
    const parentItems = document.querySelectorAll('ul.group_for_indexes > li > a.group_for_index.area');
    Array.prototype.forEach.call(parentItems, function (element: Element) {
      if (!element.classList.contains('off')) {
        element.classList.add('off');
      }
      const groupLinkClassName = (element as HTMLElement).dataset.groupLinkClass;
      const childElements = document.getElementsByClassName(groupLinkClassName);
      Array.prototype.forEach.call(childElements, function (childElement: Element) {
        if (!childElement.classList.contains('hidden')) {
          childElement.classList.add('hidden');
        }
      });
    });
  }, [windowComponent, showNestedUrlComponent]);

  const toggleGroup = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const target = event.currentTarget as HTMLElement;
    const groupLinkClassName = target.dataset.groupLinkClass;
    const targetElements = document.getElementsByClassName(groupLinkClassName);
    target.classList.toggle('off');
    Array.prototype.forEach.call(targetElements, function (element: Element) {
      element.classList.toggle('hidden');
    });
  }

  return (
    <>
      {windowComponent.contentGroups.find((group) => group.popular) && (
        <ul className="popular_group_for_indexes">
          {windowComponent.contentGroups.find((group) => group.popular).contents.map((content, contentIndex) => (
            <li className="leaf_wrapper" key={contentIndex}>
              <ContentLink content={content} groupPopular={true}/>
            </li>
          ))}
        </ul>
      )}
      <ul className="group_for_indexes">
        {windowComponent.contentGroups
          .filter((group) => !group.popular)
          .map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <li className="group_for_index_wrapper">
                <a
                  href="#"
                  onClick={toggleGroup}
                  className="group_for_index area off"
                  data-group-link-class={`group-${groupIndex}`}
                >
                  {group.name && <span className="name">{group.name}</span>}
                  <div className="icon_font icf_arrow_down"/>
                  <div className="icon_font icf_arrow_up"/>
                </a>
              </li>
              {group.contents.map((content, contentIndex) => (
                <li className={`leaf_wrapper hidden group-${groupIndex}`} key={contentIndex}>
                  {content.nested ? (
                    <ContentNestedLink
                      content={content}
                      windowComponentType={windowComponent.type as WindowComponentTypeNames}
                      showNestedUrlComponent={showNestedUrlComponent}
                      highlighted={true}
                    />
                  ) : (
                    <ContentLink content={content} groupPopular={false} highlighted={true}/>
                  )}
                </li>
              ))}
            </React.Fragment>
          ))}
      </ul>
    </>
  )
}

type ContentLinkProps = {
  content: Content;
  groupPopular?: boolean;
  highlighted?: boolean;
}
const ContentLink = ({content, groupPopular = false, highlighted = false}: ContentLinkProps) => {
  return (
    <a
      href={content.url}
      className={`leaf ${content.shop_count === 0 || !content.shop_count ? 'disabled' : ''} ${highlighted ? 'highlighted' : ''}`}
    >
      {content.name && <span className="name">{content.name}</span>}
      {content.shop_count && (
        groupPopular ? (
          <span className="shop_count">({content.shop_count})</span>
        ) : (
          <span className="shop_count">{content.shop_count}店舗</span>
        )
      )}
    </a>
  )
}

type ContentNestedLinkProps = {
  content: Content;
  windowComponentType: WindowComponentTypeNames;
  showNestedUrlComponent: (type: WindowComponentTypeNames, url: string) => void;
  // groupPopular?: boolean;
  highlighted?: boolean;
}
const ContentNestedLink = ({content, windowComponentType, showNestedUrlComponent, highlighted = false}: ContentNestedLinkProps) => {
  return (
    <a
      href={content.url}
      onClick={(e) => {
        e.preventDefault();
        showNestedUrlComponent(windowComponentType, content.url);
      }}
      className={`leaf ${!content.nested && (content.shop_count === 0 || !content.shop_count) ? 'disabled' : ''} ${highlighted ? 'highlighted' : ''}`}
    >
      {content.name && <span className="name">{content.name}</span>}
      {content.shop_count && <span className="shop_count">{content.shop_count}店舗</span>}
    </a>
  )
}
